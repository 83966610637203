exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-download-wurdle-tsx": () => import("./../../../src/pages/download/wurdle.tsx" /* webpackChunkName: "component---src-pages-download-wurdle-tsx" */),
  "component---src-pages-download-wurdle-with-friends-tsx": () => import("./../../../src/pages/download/wurdle-with-friends.tsx" /* webpackChunkName: "component---src-pages-download-wurdle-with-friends-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-bcc-text-tsx": () => import("./../../../src/pages/projects/bcc-text.tsx" /* webpackChunkName: "component---src-pages-projects-bcc-text-tsx" */),
  "component---src-pages-projects-goals-tsx": () => import("./../../../src/pages/projects/goals.tsx" /* webpackChunkName: "component---src-pages-projects-goals-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-reverse-streaks-tsx": () => import("./../../../src/pages/projects/reverse-streaks.tsx" /* webpackChunkName: "component---src-pages-projects-reverse-streaks-tsx" */),
  "component---src-pages-projects-wurdle-with-friends-tsx": () => import("./../../../src/pages/projects/wurdle-with-friends.tsx" /* webpackChunkName: "component---src-pages-projects-wurdle-with-friends-tsx" */)
}

